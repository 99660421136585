import React, { Component } from 'react'
import { Link, graphql } from 'gatsby'
import PageLayout from '../components/layouts/PageLayout'
import get from 'lodash/get';
import './../assets/styles/app.scss'

export default class PaymentProcessing extends Component {
    constructor(props) {
        super(props)

        this.message = get(this.props, 'location.state.message', "Payment failed");
    }

    render() {
        let sitemap = get(this.props, 'data.allContentfulWidgetSitemap.edges', [])
        sitemap = sitemap.map(link => link && link.node)
        return (
            <PageLayout className={`app-root processing-payment`} sitemap={sitemap}>
                <main className={`wrapper full-page`}>
                    <h2>Payment Failed</h2>
                    <p>{this.message}. Please <Link to={"/foundation/donation-form?failed=true"} >try again </Link> later or send an email to starshipfoundation@adhb.govt.nz</p>
                </main>
            </PageLayout>
        )
    }
}

export const pageQuery = graphql`
query getSiteMapDonationFailed {
  allContentfulWidgetSitemap {
      edges {
          node {
              contentful_id
              userSection
              title
              slug
              links {
                  __typename
                  ... on ContentfulPageCustom {
                      slug
                      title
                      contentful_id
                      userSection
                  }
                  ... on ContentfulPageDirectoryOfServices {
                      id
                      title
                  }
                  ... on ContentfulPageDonation {
                      slug
                      title
                      contentful_id
                  }
                  ... on ContentfulPageGeneric {
                      slug
                      title
                      userSection
                  }
                  ... on ContentfulPageGroup {
                      slug
                      title
                      userSection
                  }
                  ... on ContentfulPageGuideline {
                      title
                      slug
                  }
                  ... on ContentfulPageWidgets {
                      title
                      slug
                      userSection
                  }
              }
          }
      }
  }
}`